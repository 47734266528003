export const environment = {
  production: true,
  disableLogging: true,
  isLocalAssets: false,
  isFixedOne3dFileName: false,
  encryptionPassword: 'UcK*hCW(7qcf{N3d',
  fmKey: 'eYzUkRzfih9WxfiqSp3gep',
  apiUrl: 'https://prod-apileapmotors-au.one3d.in/',
  assetsUrl: 'https://d282vjma0vnstb.cloudfront.net/',
  assetsUrlLocal: 'assets/',
  function_js: 'one3d_functions.min.js',
  isEncrypted: false,
  apiVersion: 1,
  languageCode: 'en-US',
  loginPayload: {
    username: 'U2FsdGVkX18jrGBdGbjQy3Ox8Jr5gZEC1kH7jGwWj6uMFFbCcm69p+xgnlVHBQVD',
    password: 'U2FsdGVkX1/3kh8Hh7wkZsy5aGdS01rW3C6nWfwuT4brgq46FiB8AU9Rf0zKO5vA',
    company_token: '3d3286f7cd19074f04e514b0c6c237e757513fb32820698b790e1dec801d947a',
  },
  demoPDF: 'https://s28.q4cdn.com/392171258/files/doc_downloads/test.pdf',
  testDriveRedirectURL: "https://www.leapmotor.com/global/RFITestVersion.html",
  brandWebsite: 'https://www.leapmotor.com/au_en',
  isDisplayPrice: true,
  autoTestDriveFormTimer: 90,
};
